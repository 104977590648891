import {
  BOOK_ALL_INVOICE_PAGE,
  BOOK_ALL_LISTING_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_ALL_BOOKING_PAGE,
} from 'constants/route.constants';

import { checkIsV2 } from './helpers';

export const setV2URL = (originURL, searchParams) => {
  const urlSearchParams = searchParams ?? new URLSearchParams(searchParams);
  if (checkIsV2()) {
    urlSearchParams.set('v2', 'true');
  }
  const search = urlSearchParams.toString();
  return search ? `${originURL}?${search}` : originURL;
};
export const getListingPageUrl = ({ quotesId, listingId, searchParams }) => {
  const dest = LISTING_PAGE.replace(
    /:quotesId|:listingId/g,
    (match) => ({ ':quotesId': quotesId, ':listingId': listingId })[match],
  );

  return setV2URL(dest, searchParams);
};
export const getInvoicePageUrl = ({ quotesId, listingId }) =>
  setV2URL(
    INVOICE_PAGE.replace(
      /:quotesId|:listingId/g,
      (match) => ({ ':quotesId': quotesId, ':listingId': listingId })[match],
    ),
  );
export const getInvoiceAllPageUrl = ({ quotesId }) =>
  setV2URL(BOOK_ALL_INVOICE_PAGE.replace(':quotesId', quotesId));
export const getQuotesPageUrl = ({ quotesId }) => setV2URL(MAIN_PAGE.replace(':quotesId', quotesId));
export const getSuccessUnpaidAllPageUrl = ({ quotesId }) =>
  setV2URL(SUCCESS_UNPAID_ALL_BOOKING_PAGE.replace(':quotesId', quotesId));
export const getAllListingPageUrl = ({ quotesId, listingId }) =>
  setV2URL(
    BOOK_ALL_LISTING_PAGE.replace(
      /:quotesId|:listingId/g,
      (match) => ({ ':quotesId': quotesId, ':listingId': listingId })[match],
    ),
  );
