import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { noop } from 'utils/helpers';
import actions from 'redux/actions';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { selectAccountId, selectListingIds, selectQuotes } from 'redux/selectors/quoteSelector';

import FeatureToggleProvider from '@guestyci/feature-toggle-fe/FeatureToggleProvider';
import { intl, locales, warningHandler } from '@guestyci/atomic-design/dist/i18n/';

const env = process.env.REACT_APP_CLUSTER;
const { FETCH_QUOTES_REQUEST, FETCH_BATCH_LISTINGS_REQUEST } = actions;
const queryClient = new QueryClient();

function OnBoarding({ children, match }) {
  const [initDone, setInitDone] = useState(false);
  const dispatch = useDispatch();
  const quotes = useSelector(selectQuotes);
  const accountId = useSelector(selectAccountId);
  const listingIds = useSelector(selectListingIds);
  const { quotesId } = match.params;

  const isPageReady = initDone && !_.isEmpty(quotes) && quotesId;

  useEffect(() => {
    const lang = window.localStorage.getItem('lang') || 'en-US';
    intl
      .init({
        currentLocale: lang,
        locales,
        warningHandler,
        fallbackLocale: 'en-US',
      })
      .then(() => setInitDone(true))
      .catch(noop);
  }, []);

  useEffect(() => {
    if (quotesId) {
      dispatch({ type: FETCH_QUOTES_REQUEST, payload: { id: quotesId } });
    }
  }, [dispatch, quotesId]);

  useEffect(() => {
    if (_.isEmpty(listingIds)) {
      return;
    }

    dispatch({
      type: FETCH_BATCH_LISTINGS_REQUEST,
      payload: { quotesId, listingIds },
    });
  }, [dispatch, listingIds, quotesId]);

  if (!isPageReady) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureToggleProvider accountId={accountId} env={env}>
        {children}
      </FeatureToggleProvider>
    </QueryClientProvider>
  );
}

export default OnBoarding;
