import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { any, isEmpty, path } from 'ramda';
import cn from 'classnames';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { convertCurrencySymbol, utcDate, mapRateOption, getReservationObj } from 'utils/helpers';
import { MOBILE } from 'constants/css/breakpoints';

import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import TextField from '@guestyci/foundation/TextField';
import useTranslations from '@guestyci/localize/useTranslations';
import { LOCATIONS } from '@guestyci/shared-enums';

import Checkbox from '../Form/Checkbox';
import Address from '../Form/Sections/Address';
import RatePlans from '../Form/Sections/RatePlans';
import InvoiceTotal from '../Form/Sections/InvoiceTotal';
import validate from '../Form/validation';
import { schema, schemaWithTos } from './schema';
import { getNumberOfGuestsTranslations, translations } from './translations';
import { formInputTranslations } from '../../translations';
import { InfoStepWrapper } from './InformationStep.styled';

const formValidate = (values, isTosRequired) =>
  validate(values, isTosRequired ? schemaWithTos : schema, { fullMessages: false });

const InformationStep = React.memo(
  ({
    address,
    breakpoint,
    checkIn,
    checkOut,
    currency,
    formRef,
    name,
    occupancy,
    price,
    form,
    setForm,
    totalPrice,
    invoiceItems = [],
    goNextStep,
    setActiveStepSubmit,
    hasInquiriesFlow,
    inquiry,
    currentRatePlanQuote,
    processQuotes,
    isPaymentRequired,
    accountId,
    inquiryId,
    bookerId,
    source,
    quotesId,
    history,
    conversationId,
    reservationId,
    requireAcceptTerms,
    termsAndConditionsUrl,
    numberOfGuests,
    isRequestToBook,
    quotes,
  }) => {
    const currencySymbol = convertCurrencySymbol(currency);
    const isMobile = breakpoint === MOBILE;

    const {
      numberOfAdults = occupancy,
      numberOfChildren = 0,
      numberOfInfants = 0,
    } = numberOfGuests || {};

    const [
      fillDetailsTitle,
      propertyText,
      addressText,
      checkInText,
      checkOutText,
      guestInfoText,
      ratePlanTitle,
      invoiceTitle,
      taxesText,
      feesText,
      cleaningFeeText,
      subtotalText,
      subtotalBeforeTaxesText,
      totalPayoutText,
      totalText,
      nightText,
    ] = useTranslations(translations);

    const [
      firstNameText,
      lastNameText,
      emailText,
      phoneNumberText,
      streetText,
      cityText,
      countryText,
      zipCodeText,
      userAgreementText,
      tosText,
    ] = useTranslations(formInputTranslations);

    const isTosRequired = useMemo(
      () => requireAcceptTerms && termsAndConditionsUrl && termsAndConditionsUrl.length,
      [requireAcceptTerms, termsAndConditionsUrl],
    );

    const [numberOfGuestTitle, numberOfGuestValue] = useTranslations(
      getNumberOfGuestsTranslations({
        numberOfAdults,
        numberOfChildren,
        numberOfInfants,
      }),
      [numberOfAdults, numberOfChildren, numberOfInfants],
    );

    const onSubmit = (values) => {
      setForm({ ...form, guestInfoValues: values });
      if (isPaymentRequired) {
        goNextStep();
      } else {
        processQuotes({
          accountId,
          quotes,
          data: getReservationObj({
            ...values,
            inquiryId,
            source,
            bookerId,
            isPaymentRequired,
            conversationId,
            reservationId,
            ratePlanId: values.ratePlan?.value,
            isRequestToBook,
          }),
          history,
          quotesId,
        });
      }
    };

    const onStepSubmit = () => formRef.current.form.submit;

    useEffect(() => {
      setActiveStepSubmit(onStepSubmit);
      // eslint-disable-next-line
    }, []);

    const ratePlans = path(['rates', 'ratePlans'], inquiry) || [];
    const coupons = path(['coupons'], inquiry) || [];
    const promotions = path(['promotions'], inquiry) || {};
    const channel = path(['_id', 'channel'], inquiry);
    const ratePlanOptions = ratePlans.map(mapRateOption);

    useEffect(() => {
      if (hasInquiriesFlow) {
        const defaultRatePlan = inquiry?.rates?.ratePlans[0];
        const ratePlansLength = inquiry?.rates?.ratePlans?.length;
        setForm({
          ...form,
          guestInfoValues: {
            ...form.guestInfoValues,
            ...(ratePlansLength === 1 && { ratePlan: mapRateOption(defaultRatePlan) }),
          },
        });
      }
    }, [inquiry, setForm, hasInquiriesFlow]);

    const onRatePlanChange = (change, values) => (evt) => {
      const { value } = evt.target;
      setForm({
        ...form,
        guestInfoValues: {
          ...values,
          ratePlan: value,
        },
      });

      change('ratePlan', value);
    };

    return (
      <InfoStepWrapper>
        <TextField variant="h1">{fillDetailsTitle}</TextField>
        <div className={cn('d-flex', { 'flex-column': isMobile })}>
          <div className="mr-8 mt-6">
            <TextField className="mb-1" color="secondary" style={{ fontSize: '12px' }}>
              {propertyText}
            </TextField>
            <TextField>{name}</TextField>
          </div>
        </div>
        <div className="mt-4">
          <TextField className="mb-1" color="secondary" style={{ fontSize: '12px' }}>
            {addressText}
          </TextField>
          <TextField>{address}</TextField>
        </div>
        <div className="d-flex mt-4">
          <div className="mr-8">
            <TextField className="mb-1" color="secondary" style={{ fontSize: '12px' }}>
              {checkInText}
            </TextField>
            <TextField>{format(utcDate(checkIn), 'MMM dd, yyyy')}</TextField>
          </div>
          <div className="mr-8">
            <TextField className="mb-1" color="secondary" style={{ fontSize: '12px' }}>
              {checkOutText}
            </TextField>
            <TextField>{format(utcDate(checkOut), 'MMM dd, yyyy')}</TextField>
          </div>
          <div>
            <TextField className="mb-1" color="secondary" style={{ fontSize: '12px' }}>
              {numberOfGuestTitle}
            </TextField>
            <TextField>{numberOfGuestValue}</TextField>
          </div>
        </div>
        <FinalForm
          ref={formRef}
          onSubmit={onSubmit}
          validate={(values) => formValidate(values, isTosRequired)}
          initialValues={form.guestInfoValues}
          render={({ handleSubmit, values, form: { change } }) => (
            <Form
              onSubmit={handleSubmit}
              provider={FormProvider.Final}
              fieldInstance={Field}
              className="w-100"
            >
              <FormSpy
                subscription={{
                  submitting: true,
                  valid: true,
                  touched: true,
                }}
                onChange={(state) => {
                  const { submitting, valid, touched } = state;
                  if (setForm) {
                    const ratePlansLength = inquiry?.rates?.ratePlans?.length;
                    setForm((formOld) => ({
                      ...formOld,
                      disabled:
                        (!values.ratePlan && ratePlansLength > 1) ||
                        any((val) => !val, Object.values(touched)) ||
                        isEmpty(touched)
                          ? false
                          : submitting || !valid,
                    }));
                  }
                }}
              />

              {/** address */}
              <Address
                guestInfoText={guestInfoText}
                firstNameText={firstNameText}
                lastNameText={lastNameText}
                streetText={streetText}
                cityText={cityText}
                countryText={countryText}
                zipCodeText={zipCodeText}
                emailText={emailText}
                phoneNumberText={phoneNumberText}
                countriesList={LOCATIONS.countries}
                isMobile={isMobile}
              />

              {/** Rate plan */}
              {hasInquiriesFlow && (
                <RatePlans
                  ratePlan={values.ratePlan}
                  ratePlanTitle={ratePlanTitle}
                  options={ratePlanOptions}
                  list={ratePlans}
                  channel={channel}
                  onRatePlanChange={onRatePlanChange(change, values)}
                />
              )}

              {/** invoice total *!/ */}
              <InvoiceTotal
                isSingle
                invoiceTitle={invoiceTitle}
                nightText={nightText}
                checkOut={checkOut}
                checkIn={checkIn}
                invoiceItems={invoiceItems}
                currencySymbol={currencySymbol}
                totalText={totalText}
                totalPrice={totalPrice}
                price={price}
                hasInquiriesFlow={hasInquiriesFlow}
                coupons={coupons}
                promotions={promotions}
                currentRatePlanQuote={currentRatePlanQuote}
                taxesText={taxesText}
                feesText={feesText}
                cleaningFeeText={cleaningFeeText}
                subtotalText={subtotalText}
                subtotalBeforeTaxesText={subtotalBeforeTaxesText}
                totalPayoutText={totalPayoutText}
              />

              {isTosRequired && (
                <div className="mt-4 invoice-checkbox">
                  <Field name="termOfUse" component={Checkbox}>
                    {userAgreementText}{' '}
                    <a href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
                      {tosText}
                    </a>
                  </Field>
                </div>
              )}
            </Form>
          )}
        />
      </InfoStepWrapper>
    );
  },
);

export default InformationStep;
