import { intlHelper } from './helpers';

export const headerIntlId = intlHelper('main_page.header.');

export const listingComponentIntlId = intlHelper('main_page.listing_component.');

export const mainPageIntlId = intlHelper('main_page.');

export const pluralizeTagsIntlId = intlHelper('pluralize_tags.');

export const listingPageIntlId = intlHelper('listing_page.listing.');

export const listingPageFooterIntlId = intlHelper('listing_page.footer.');

export const invoicePageFooterIntlId = intlHelper('invoice_page.footer.');

export const invoicePageInvoiceIntlId = intlHelper('invoice_page.invoice.');

export const expiredPageIntlId = intlHelper('expiredPage.');

export const reservationPageIntlId = intlHelper('reservationPage.');

export const notAvailablePage = intlHelper('noAvailableP.');

export const successPageIntlId = intlHelper('successPage.');

export const technicalPageIntlId = intlHelper('technical_page.');

export const validationsIntlId = intlHelper('validations.');

export const invoiceItemsIntlId = intlHelper('invoice_page_invoice_item.');

export const oopsPageIntlId = intlHelper('oops_page.');

export const errorDialogIntlId = intlHelper('error_dialog.');
