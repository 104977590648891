import { createSelector } from 'reselect';
import _ from 'lodash';

const pickQuotes = (state) => state.quotes.quotes;
const pickListingCollections = (state) => state.quotes.listingCollection;
const pickReservationCollections = (state) => state.quotes.reservationCollection;

export const selectReservationCollection = createSelector(pickQuotes, (quotes) => {
  const reservationsList = _.get(quotes, 'meta.reservations', []);

  return _.keyBy(reservationsList, '_id');
});

export const selectSendQuote = createSelector(pickQuotes, (quotes) => quotes?.meta?.sendQuote);

export const selectInquiryReservationIds = createSelector(pickQuotes, (quotes) => {
  const reservations = _.get(quotes, 'meta.reservations', []);

  const inquiryReservations = _.filter(reservations, { status: 'inquiry' });

  return _.map(inquiryReservations, '_id');
});

export const selectQuotes = createSelector(pickQuotes, (quotes) => quotes);

export const selectAccountId = createSelector(pickQuotes, (quotes) => _.get(quotes, 'meta.account._id'));
export const selectQuoteSettings = createSelector(pickQuotes, (quotes) =>
  _.get(quotes, 'meta.account.quoteSettings'),
);

export const selectListingIds = createSelector(pickQuotes, (quotes) => {
  const reservations = _.get(quotes, 'meta.reservations');

  return _.map(reservations, 'listing._id');
});

export const selectListingCollection = createSelector(
  pickListingCollections,
  (listingCollection) => listingCollection,
);

export const selectQuoteIntegration = createSelector(selectQuotes, (quote) =>
  _.get(quote, 'meta.integration', {}),
);

export const selectListingById = (id) =>
  createSelector(pickListingCollections, (collection) => _.get(collection, id, {}));

export const selectReservationById = (id) =>
  createSelector(pickReservationCollections, (collection) => _.get(collection, id, {}));

export const selectReservationByIdTotalPricing = (id) =>
  createSelector(pickReservationCollections, (collection) => {
    const reservation = _.get(collection, id);
    const money = _.get(reservation, 'inquiry.rates.ratePlans[0].quote.money');

    const currency = _.get(money, 'currency', 'USD');
    const hostPayout = _.get(money, 'hostPayout', 0);

    return {
      currency,
      hostPayout,
    };
  });

export const selectTotalPriceAndCurrencyForGroup = createSelector(
  [selectInquiryReservationIds, selectReservationCollection],
  (ids, collection) => {
    const hostPayoutList = ids.map((id) => {
      const reservation = collection[id];
      return reservation?.inquiry?.rates?.ratePlans[0]?.quote.money.hostPayout;
    });

    return {
      totalAmount: _.sum(hostPayoutList),
      currency: collection[ids[0]]?.inquiry?.rates?.ratePlans[0]?.quote.money.currency,
    };
  },
);

export const selectIsPaymentRequired = createSelector(
  selectQuotes,
  (quotes) => quotes.requirePaymentMethod ?? quotes?.meta?.account?.quoteSettings?.requirePaymentMethod,
);

export const selectQuoteFilters = createSelector(selectQuotes, (quotes) => quotes.filters);

export const selectPaymentInfo = createSelector(selectQuotes, (quotes) => ({
  providerType: _.get(quotes, 'meta.reservations[0].providerType'),
  paymentProviderId: _.get(quotes, 'meta.reservations[0].paymentProviderId'),
  providerAccountId: _.get(quotes, 'meta.reservations[0].providerAccountId'),
}));
