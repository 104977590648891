import { path } from 'ramda';
import { useQuery } from '@tanstack/react-query';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkIsV2 } from 'utils/helpers';
import { selectQuotes } from 'redux/selectors/quoteSelector';

import Resource from '@guestyci/agni';

const { api } = Resource.create('public/send-quote');

export const usePaymentProvider = () => {
  const { params } = useRouteMatch();
  const quotes = useSelector(selectQuotes);

  const isV2 = checkIsV2();
  const accountId = quotes?.meta?.account?._id;

  const reservation =
    quotes?.meta?.reservations?.find((res) => res.listing._id === params.listingId) ??
    quotes?.meta?.reservations?.[0];

  const listingId = params?.listingId ?? reservation?.listing?._id;

  const { data: paymentProviderData } = useQuery({
    enabled: isV2,
    queryKey: [`payment-provider`, params.quotesId, listingId, accountId],
    queryFn: () => api.get(`payment-provider/${params.quotesId}/${listingId}/${accountId}`),
  });

  const providerAccountId = isV2
    ? paymentProviderData?.data?.providerAccountId
    : (reservation?.providerAccountId ?? path(['meta', 'account', 'stripeAccountId'], quotes));

  const paymentProviderId = isV2
    ? paymentProviderData?.data?.paymentProviderId
    : reservation?.paymentProviderId;

  const providerType = isV2 ? paymentProviderData?.data?.providerType : reservation?.providerType;

  const isProcessingActive =
    (providerType === 'stripe' && providerAccountId) ||
    (providerType === 'amaryllis' && paymentProviderId);

  return {
    isPaymentsActive: isV2 ? paymentProviderData?.data?.isPaymentsActive : true,
    providerType,
    paymentProviderId,
    providerAccountId,
    isProcessingActive,
  };
};
