import React from 'react';
import PropTypes from 'prop-types';

import useTranslations from '@guestyci/localize/useTranslations';
import TextField from '@guestyci/foundation/TextField';

import { ratesTranslations, CANCEL_FEE_0 } from '../../InformationStep/translations';

const RateInfo = ({ mealPlans, policy, fee }) => {
  const [cancellationPolicyValue, cancellationFeeValue, mealPlansValue] = useTranslations(
    ratesTranslations(policy, fee, mealPlans),
    [policy, fee, mealPlans],
  );
  return (
    <div className="bg-gray-lighter mb-2 px-3 py-2">
      <ul className="list-group ml-2">
        {!!policy && (
          <li key={cancellationPolicyValue}>
            <TextField variant="h7" color="default">
              {cancellationPolicyValue}
            </TextField>
          </li>
        )}
        {fee !== CANCEL_FEE_0 && (
          <li key={cancellationFeeValue}>
            <TextField variant="h7" color="default">
              {cancellationFeeValue}
            </TextField>
          </li>
        )}
        {!!mealPlans?.length && (
          <li key={mealPlansValue}>
            <TextField variant="h7" color="default">
              {mealPlansValue}
            </TextField>
          </li>
        )}
      </ul>
    </div>
  );
};

RateInfo.propTypes = {
  ratesCount: PropTypes.number,
  selectedRates: PropTypes.arrayOf(
    PropTypes.shape({
      ratePlanId: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      cancellationPolicy: PropTypes.string,
      cancellationFee: PropTypes.string,
      isCancellationPolicyByChannels: PropTypes.bool,
      cancellationPolicyByChannels: PropTypes.shape(),
      mealPlans: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default RateInfo;
