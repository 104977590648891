import React from 'react';
import { path } from 'ramda';
import { connect } from 'react-redux';

import { SimplifiedListingComponent } from './SimplifiedListingComponent';
import { SimplifiedListings, SimplifiedListingsTitle } from './SimplifiedListings.styled';

const SimplifiedListingsComponent = ({ quotes, history, quotesId, listingId }) => {
  const listings = path(['meta', 'reservations'], quotes) || [];
  const nonSelectedListings = listings.filter(({ listing }) => listing._id !== listingId);

  return nonSelectedListings.length ? (
    <SimplifiedListings className="d-flex flex-column overflow-auto flex-shrink-0">
      <SimplifiedListingsTitle className="font-size-21 color-gray font-weight-bold">
        More Listings
      </SimplifiedListingsTitle>
      {nonSelectedListings.map(({ listing: { _id, picture, roomType, title }, price, inquiry }) => (
        <SimplifiedListingComponent
          key={_id}
          listingId={_id}
          picture={picture}
          quotesId={quotesId}
          title={title}
          roomType={roomType}
          price={price}
          history={history}
          inquiry={inquiry}
        />
      ))}
    </SimplifiedListings>
  ) : null;
};

const mapStateToProps = (state) => ({
  quotes: state.quotes.quotes,
});

export default connect(mapStateToProps, null)(SimplifiedListingsComponent);
