import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MailToStringTag = styled.div`
  display: inline;

  a {
    color: inherit;
  }
`;

const MailToString = ({ email }) => {
  if (!email) {
    return null;
  }

  return (
    <MailToStringTag>
      &nbsp;at&nbsp;
      <a href={`mailto:${email}`} target="_top">
        {email}
      </a>
    </MailToStringTag>
  );
};

MailToString.propTypes = {
  email: PropTypes.string,
};

export default MailToString;
