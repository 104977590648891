import { merge } from 'ramda';
import ActionTypes from 'redux/actions';

const { PROCESS_INVOICE_INPROGRESS, UPDATE_GUESTS } = ActionTypes;

const initialState = {
  isProcessingInvoice: false,
  guests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_INVOICE_INPROGRESS:
      return merge(state, {
        isProcessingInvoice: !!action.payload,
      });
    case UPDATE_GUESTS:
      return merge(state, {
        guests: action.payload,
      });

    default:
      return state;
  }
};
