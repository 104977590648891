import React, { useRef, useContext, createContext, useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectQuotes } from 'redux/selectors/quoteSelector';
import { lg } from 'constants/css/breakpoints';

import createStyles from '@guestyci/foundation/createStyles';

import LogoTag from '../../LogoTag/LogoTag';

const CSS_FOOTER_HEIGHT_PROPERTY = '--footerFixedHeight';

const useClasses = createStyles(({ spacer }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'relative',
    zIndex: 1,
    width: '100vw',
    height: '100vh',
  },
  bgGradient: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 470px, 100%',
    backgroundImage: 'linear-gradient(to bottom, #f9f9f9, #efefef), linear-gradient(white, white)',
  },
  defaultBg: {
    backgroundColor: '#f9f9f9',
  },
  body: {
    position: 'absolute',
    inset: 0,
    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',

    height: `calc(100% - var(${CSS_FOOTER_HEIGHT_PROPERTY}))`,
  },
  logo: {},
  footer: {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    zIndex: 10,
    right: 0,
    bottom: 0,
    left: 0,
  },
  container: {
    maxWidth: `${lg}px`,
    width: '100%',
    margin: '0 auto',
    padding: `0 ${spacer(3)}px`,
    flexGrow: 1,
  },
  containerFluid: {
    width: '100%',
    padding: `0 ${spacer(3)}px`,
    flexGrow: 1,
  },
}));

const LayoutCtx = createContext({
  bodyRef: { current: null },
  footerRef: { current: null },
});

function Layout({ children, className, multipleBackground = true }) {
  const classes = useClasses();

  const bodyRef = useRef();
  const footerRef = useRef();

  return (
    <LayoutCtx.Provider value={{ footerRef, bodyRef }}>
      <div
        className={cn(classes.root, className, {
          [classes.bgGradient]: multipleBackground,
          [classes.defaultBg]: !multipleBackground,
        })}
      >
        {children}
      </div>
    </LayoutCtx.Provider>
  );
}

function Logo() {
  const quotes = useSelector(selectQuotes);

  return <LogoTag quotes={quotes} />;
}

function FooterLayout({ children, className, fixed, isHidden = false }) {
  const classes = useClasses();
  const { footerRef } = useContext(LayoutCtx);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((ent) => {
      ent.forEach((el) => {
        if (!isHidden && fixed) {
          const footerHeight = footerRef.current?.offsetHeight ?? 0;
          const height = `${footerHeight}px`;

          document.documentElement.style.setProperty(CSS_FOOTER_HEIGHT_PROPERTY, height);
        } else {
          document.documentElement.style.setProperty(CSS_FOOTER_HEIGHT_PROPERTY, '0px');
        }
      });
    });

    const bodyElement = document.querySelector('body');

    resizeObserver.observe(bodyElement);

    return () => resizeObserver.disconnect();
  }, [isHidden, fixed, footerRef]);

  if (isHidden) {
    return null;
  }

  return (
    <div
      ref={footerRef}
      className={cn(classes.footer, className, {
        'position-fixed': fixed,
        'position-sticky': !fixed,
      })}
    >
      {children}
    </div>
  );
}

function BodyLayout({ children, className }) {
  const classes = useClasses();
  const { bodyRef } = React.useContext(LayoutCtx);

  return (
    <div ref={bodyRef} className={cn(classes.body, className)}>
      {children}
    </div>
  );
}

function ContainerLayout({ children, className }) {
  const classes = useClasses();

  return <div className={cn(classes.container, className)}>{children}</div>;
}

function ContainerLayoutFluid({ children, className }) {
  const classes = useClasses();

  return <div className={cn(classes.containerFluid, className)}>{children}</div>;
}

Layout.Body = BodyLayout;
Layout.Container = ContainerLayout;
Layout.ContainerFluid = ContainerLayoutFluid;
Layout.Logo = Logo;
Layout.Footer = FooterLayout;

export default Layout;
